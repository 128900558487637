import { validate } from "isemail";
import { onReady } from "./util";
import "index.css";

const VALIDATE_FAIL = "👎";
const VALIDATE_SUCCESS = "👍";

let canSubmit = false;

function go() {
  const form = document.querySelector("form");
  const input: HTMLInputElement = form.querySelector("input[name='email']");
  const button = form.querySelector("button");

  input.onfocus = () => {
    document.body.classList.add("nvert");
  };

  input.onblur = () => {
    if (!canSubmit) {
      document.body.classList.remove("nvert");
    }
  };

  input.addEventListener("keyup", () => {
    if (validate(input.value)) {
      button.innerText = VALIDATE_SUCCESS;
      canSubmit = true;
    } else {
      button.innerText = VALIDATE_FAIL;
      canSubmit = false;
    }
  });
}

onReady().then(go);
